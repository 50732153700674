var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm._m(0),
      _c("br"),
      _c("h2", [_vm._v("# Trend 组件 ")]),
      _c("a-divider", [_vm._v(" 正常 ")]),
      _c(
        "a-card",
        [
          _c(
            "trend",
            { staticStyle: { "margin-right": "16px" }, attrs: { flag: "up" } },
            [
              _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                _vm._v("工资")
              ]),
              _vm._v("\n      5%\n    ")
            ]
          ),
          _c(
            "trend",
            { staticStyle: { "margin-right": "16px" }, attrs: { flag: "up" } },
            [
              _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                _vm._v("工作量")
              ]),
              _vm._v("\n      50%\n    ")
            ]
          ),
          _c("trend", { attrs: { flag: "down" } }, [
            _c("span", { attrs: { slot: "term" }, slot: "term" }, [
              _vm._v("身体状态")
            ]),
            _vm._v("\n      50%\n    ")
          ])
        ],
        1
      ),
      _c("a-divider", [_vm._v(" 颜色反转 ")]),
      _c(
        "a-card",
        { staticStyle: { "margin-bottom": "3rem" } },
        [
          _c(
            "trend",
            {
              staticStyle: { "margin-right": "16px" },
              attrs: { flag: "up", "reverse-color": true }
            },
            [
              _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                _vm._v("工资")
              ]),
              _vm._v("\n      5%\n    ")
            ]
          ),
          _c(
            "trend",
            {
              staticStyle: { "margin-right": "16px" },
              attrs: { flag: "down", "reverse-color": true }
            },
            [
              _c("span", { attrs: { slot: "term" }, slot: "term" }, [
                _vm._v("工作量")
              ]),
              _vm._v("\n      50%\n    ")
            ]
          )
        ],
        1
      ),
      _c("h2", [_vm._v("# AvatarList 组件 ")]),
      _c("a-divider", [_vm._v(" AvatarList ")]),
      _c(
        "a-card",
        { staticStyle: { "margin-bottom": "3rem" } },
        [
          _c(
            "avatar-list",
            { attrs: { "max-length": 3 } },
            [
              _c("avatar-list-item", {
                attrs: {
                  tips: "Jake",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Andy",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Niko",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Niko",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Niko",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Niko",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Niko",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png"
                }
              })
            ],
            1
          ),
          _c("a-divider", {
            staticStyle: { margin: "0 16px" },
            attrs: { type: "vertical" }
          }),
          _c(
            "avatar-list",
            { attrs: { size: "mini" } },
            [
              _c("avatar-list-item", {
                attrs: {
                  tips: "Jake",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Andy",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png"
                }
              }),
              _c("avatar-list-item", {
                attrs: {
                  tips: "Niko",
                  src:
                    "https://gw.alipayobjects.com/zos/rmsportal/kZzEzemZyKLKFsojXItE.png"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("h2", [_vm._v("# CountDown 组件 ")]),
      _c("a-divider", [_vm._v(" CountDown ")]),
      _c(
        "a-card",
        { staticStyle: { "margin-bottom": "3rem" } },
        [
          _c("count-down", {
            staticStyle: { "font-size": "2rem" },
            attrs: {
              target: new Date().getTime() + 3000000,
              "on-end": _vm.onEndHandle
            }
          }),
          _c("a-divider", {
            staticStyle: { margin: "0 16px" },
            attrs: { type: "vertical" }
          }),
          _c("count-down", {
            staticStyle: { "font-size": "2rem" },
            attrs: {
              target: new Date().getTime() + 10000,
              "on-end": _vm.onEndHandle2
            }
          })
        ],
        1
      ),
      _c("h2", [_vm._v("# Ellipsis 组件 ")]),
      _c("a-divider", [_vm._v(" Ellipsis ")]),
      _c(
        "a-card",
        { staticStyle: { "margin-bottom": "3rem" } },
        [
          _c("ellipsis", { attrs: { length: 100, tooltip: "" } }, [
            _vm._v(
              "\n      There were injuries alleged in three cases in 2015, and a\n      fourth incident in September, according to the safety recall report. After meeting with US regulators in October, the firm decided to issue a voluntary recall.\n    "
            )
          ])
        ],
        1
      ),
      _c("h2", [_vm._v("# NumberInfo 组件 ")]),
      _c("a-divider", [_vm._v(" NumberInfo ")]),
      _c(
        "a-card",
        [
          _c("number-info", {
            attrs: {
              "sub-title": function() {
                return "Visits this week"
              },
              total: 12321,
              status: "up",
              "sub-total": 17.1
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("img", {
        staticStyle: { width: "64px", height: "64px" },
        attrs: { alt: "Vue logo", src: require("../assets/logo.png") }
      }),
      _c("h3", { staticStyle: { "margin-top": "1rem" } }, [
        _vm._v("Welcome to Your Vue.js App")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }