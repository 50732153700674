<template>
  <div class="index">
    <div class="banner">
      <div class="txt">用户协议</div>
    </div>
    <div class="content">
      <p
        style="
          text-align: center;
          font-family: 等线;
          font-size: 14px;
          text-wrap: wrap;
          line-height: 28px;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; line-height: 40px; color: rgb(51, 51, 51); font-size: 20px"
          ><span style="font-family: 宋体">《毅力实验通》用户协议</span></span
        >
      </p>
      <p style="text-align: right; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)">
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">发布日期：</span>2023<span style="font-family: 宋体">年</span
          ><span style="font-family: Raleway">8</span><span style="font-family: 宋体">月</span
          ><span style="font-family: Raleway">12</span><span style="font-family: 宋体">日</span></span
        >
      </p>
      <p style="text-align: right; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)">
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">生效日期：</span>2023<span style="font-family: 宋体">年</span
          ><span style="font-family: Raleway">8</span><span style="font-family: 宋体">月</span
          ><span style="font-family: Raleway">12</span><span style="font-family: 宋体">日</span></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap; line-height: 28px; background: rgb(255, 255, 255)">
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">感谢您使用毅力实验通产品及相关服务！</span></span
          ></strong
        >
      </p>
      <p
        style="
          margin: 20px 0 5px;
          font-family: 等线;
          font-size: 14px;
          text-wrap: wrap;
          text-indent: 32px;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">在您开始使用</span><span style="font-family: Raleway">“</span
          ><span style="font-family: 宋体">毅力实验通</span><span style="font-family: Raleway">”</span
          ><span style="font-family: 宋体">产品（以下也会简称</span><span style="font-family: Raleway">“</span
          ><span style="font-family: 宋体">本产品</span><span style="font-family: Raleway">”</span
          ><span style="font-family: 宋体">，包括</span><span style="font-family: Raleway">“</span
          ><span style="font-family: 宋体">毅力实验通</span><span style="font-family: Raleway">”</span
          ><span style="font-family: 宋体"
            >平台及所有客户端应用以及所有的发布版本）及相关服务之前，
            请您务必认真阅读并充分理解《毅力实验通用户协议》（以下简称</span
          ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">本协议</span
          ><span style="font-family: Raleway">”</span><span style="font-family: 宋体">），本协议是您（以下也会使用</span
          ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">用户</span
          ><span style="font-family: Raleway">”</span><span style="font-family: 宋体">来指代您）与</span
          ><span style="font-family: Raleway">“</span
          ><span style="font-family: 宋体">北京毅力澎湃技术有限公司（以下也会使用</span
          ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">本公司</span
          ><span style="font-family: Raleway">”</span><span style="font-family: 宋体">，软件出品方）</span
          ><span style="font-family: Raleway">”</span><span style="font-family: 宋体">及 </span
          ><span style="font-family: Raleway">“</span
          ><span style="font-family: 宋体">平台运营管理方（软件运营管理方）</span
          ><span style="font-family: Raleway">”</span
          ><span style="font-family: 宋体">就使用本产品及服务达成的协议。如果您未满</span
          ><span style="font-family: Raleway">18</span
          ><span style="font-family: 宋体"
            >周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后登录和使用本产品及相关服务。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <strong
          ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
            ><span style="font-family: 宋体">本公司：</span></span
          ></strong
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >即毅力实验通软件出品方，特指北京毅力澎湃技术有限公司，为毅力实验通产品的开发公司。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <strong
          ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
            ><span style="font-family: 宋体">平台运营管理方：</span></span
          ></strong
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >即毅力实验通软件运营方，特指毅力实验通产品的试用及实际购买客户，通常为私有云部署毅力实验通产品的</span
          ></span
        ><span style="font-family: 宋体; color: rgb(51, 51, 51); font-size: 14px">本科和</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >职业院校，是毅力实验通产品及服务的实际提供者，负责毅力实验通产品的日常运营和管理工作。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <strong
          ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
            ><span style="font-family: 宋体">用户：</span></span
          ></strong
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >通过手机、平台电脑、电脑等设备使用毅力实验通产品的使用者，通过毅力实验通查看、发布、接受图片、音频、视频信息或其他文件，或与其他用户进行教学的终端用户。</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap; line-height: 28px; background: rgb(255, 255, 255)">
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第一条</span>
            <span style="font-family: 宋体">本产品及服务的获取及使用</span></span
          ></strong
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">1.1</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >平台运营管理方从任何合法的渠道获得本产品并且安装到自己的设备中方可使用本产品及服务。但是任何个人或公司以及第三方组织不得以任何形式改编、复制、反编译或者交易本产品后使用本产品及服务。</span
          >
          <span style="font-family: 宋体"
            >若平台运营管理方并非从我公司授权的第三方获取产品及服务，本公司将无法保证非官方版本的本产品能够正常使用，其造成的任何损失均与本公司无关。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">1.2</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">用户通过上述的合法平台运营方获的本产品的</span>Web<span
            style="font-family: 宋体"
            >端或</span
          ><span style="font-family: Raleway">APP</span
          ><span style="font-family: 宋体">移动客户端，自行准备与软件及相关服务配套的终端设备（如电脑、手机、</span
          ><span style="font-family: Raleway">Pad</span
          ><span style="font-family: 宋体">等）进行安装， 一旦您在其终端设备中打开和登录本产品的移动应用</span
          ><span style="font-family: Raleway">APP</span><span style="font-family: 宋体">或</span
          ><span style="font-family: Raleway">Web</span
          ><span style="font-family: 宋体"
            >相关网站，即视为您使用本产品及相关服务，并意味着您已经同意本协议的相关法律条文，否则您将无权登录和使用本产品及相关服务。</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap; line-height: 28px; background: rgb(255, 255, 255)">
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第二条</span> <span style="font-family: 宋体">关于</span
            ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">账号</span
            ><span style="font-family: Raleway">”</span><span style="font-family: 宋体">的相关说明</span></span
          ></strong
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.1</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >本产品的账号由平台运营管理方提供注册，为了保障产品运营的安全性和用户的合法性，本产品通常不支持对外公开注册，仅限于平台运营方授权的合法用户团体注册或者个人注册使用，不同角色的账号享有不同的注册方式。</span
          >
          <span style="font-family: 宋体"
            >单位用户可联系平台运营管理方安排的平台管理员进行集中注册，个人用户可以通过通过分享的二维码进行扫描注册，具体注册方式详见《毅力实验通隐私政策》的相关说明。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.2</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >根据《中华人民共和国网络安全法》的有关规定看，为了避免个别账号在本产品上发布不法言论。本产品的所有账号统一使用</span
          ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">个人手机号码</span
          ><span style="font-family: Raleway">”</span><span style="font-family: 宋体">或</span
          ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">工（学）号</span
          ><span style="font-family: Raleway">”</span
          ><span style="font-family: 宋体"
            >作为登录平台的统一认证标识，
            因此需要您使用本人合法的手机号进行本产品的账号注册，避免短信验证失败导致账号无法登录或者密码无法修改的问题。本产品的账号注册从不需要提供用户的身份证、银行卡号这些个人敏感信息，
            如遇到提供上述敏感信息的要求您有权拒绝。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.3</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >您在本产品中的注册账号仅限于您本人使用，禁止赠与、借用、出租或售卖。如果本公司或平台运营管理方发现或者有理由怀疑使用者并非帐号初始注册人，则有权在未经通知的情况下，暂停或终止向该注册账号提供服务，</span
          >
          <span style="font-family: 宋体"
            >并有权注销该帐号，而无需向注册该帐号的用户承担法律责任。由此带来的包括并不限于用户通讯中断、用户资料和信息清空等损失由用户自行承担。此外对于长期未登录该产品的账号，</span
          >
          <span style="font-family: 宋体">本公司或平台运营管理方有权进行账号冻结和注销处理。</span></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.4</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户有责任维护个人帐号、密码的安全性与保密性（账号注册成功后第一次登录系统，请务必修改原始密码，并要设置复杂的个人密码以保障账号安全），用户以其注册帐号名义所从事的一切活动负全部责任，</span
          >
          <span style="font-family: 宋体"
            >包括用户数据的修改、发表的任何言论以及其他在本产品上的任何操作行为。因此，用户应高度重视帐号与密码的保密工作，若发现他人未经许可使用自己帐号或发生其他任何安全漏洞问题，</span
          >
          <span style="font-family: 宋体"
            >应立即通知本公司或平台运营管理方进行应急处理，但用户无论因何种原因发生的密码泄露以及产生的任何后果均应由用户自行承担相关责任。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.5</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户遗忘账号或密码之后可以联系平台运营管理方的管理员或客服人员，在核实身份后由其负责进行账号找回或者密码重置。在任何情况下，相关人员不应询问您关于密码的问题，如询问密码个人有权拒绝回答。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.6</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户应保证注册账号时所填写的信息真实准确。如果因用户的注册信息有误而引起的问题以及所带来的任何后果，均由用户自行承担责任。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">2.7</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >涉及用户账号以及相关个人信息的其他规定详见《隐私协议》的具体要求和说明。</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap; line-height: 28px; background: rgb(255, 255, 255)">
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第三条</span>
            <span style="font-family: 宋体">关于用户行为的合法性要求</span></span
          ></strong
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">3.1</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户在使用本产品提供的服务时，必须遵守中华人民共和国相关法律法规的规定，用户应承诺不会利用本产品上传、展示、张贴、传播或以其它任何方式传送含有下列内容之一的信息：</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap">
        <span style="font-family: Raleway; color: rgb(102, 102, 102); font-size: 14px; background: rgb(255, 255, 255)"
          >1<span style="font-family: 宋体">）反对宪法所确定的基本原则的；</span
          ><span style="font-family: Raleway">2</span
          ><span style="font-family: 宋体">）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
          ><span style="font-family: Raleway">3</span><span style="font-family: 宋体">）损害国家荣誉和利益的；</span
          ><span style="font-family: Raleway">4</span
          ><span style="font-family: 宋体">）煽动民族仇恨、民族歧视、破坏民族团结的；</span
          ><span style="font-family: Raleway">5</span
          ><span style="font-family: 宋体">）破坏国家宗教政策，宣扬邪教和封建迷信的；</span
          ><span style="font-family: Raleway">6</span
          ><span style="font-family: 宋体">）散布谣言，扰乱社会秩序，破坏社会稳定的；</span
          ><span style="font-family: Raleway">7</span
          ><span style="font-family: 宋体">）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span
          ><span style="font-family: Raleway">8</span
          ><span style="font-family: 宋体">）侮辱或者诽谤他人，侵害他人合法权利的；</span
          ><span style="font-family: Raleway">9</span
          ><span style="font-family: 宋体"
            >）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</span
          ><span style="font-family: Raleway">10</span
          ><span style="font-family: 宋体">）对他人进行暴力恐吓、威胁，实施人肉搜索的；</span
          ><span style="font-family: Raleway">11</span><span style="font-family: 宋体">）未获得未满</span
          ><span style="font-family: Raleway">18</span
          ><span style="font-family: 宋体"
            >周岁未成年人法定监护人的书面同意，传播该未成年人的隐私信息的；散布污言秽语，损害社会公序良俗的；</span
          ><span style="font-family: Raleway">12</span><span style="font-family: 宋体">）侵犯他人知识产权的；</span
          ><span style="font-family: Raleway">13</span
          ><span style="font-family: 宋体">）发布虚假信息，侵犯他人隐私，侮辱他人，造谣诽谤的；</span
          ><span style="font-family: Raleway">14</span
          ><span style="font-family: 宋体">）散布商业广告，或类似的商业招揽信息；</span
          ><span style="font-family: Raleway">15</span
          ><span style="font-family: 宋体">）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；</span
          ><span style="font-family: Raleway">16</span
          ><span style="font-family: 宋体"
            >）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</span
          ></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">3.2</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户不得利用或针对本产品进行任何危害计算机网络安全的行为，包括但不限于下列行为：</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap">
        <span style="font-family: Raleway; color: rgb(102, 102, 102); font-size: 14px; background: rgb(255, 255, 255)"
          >1<span style="font-family: 宋体">）未经允许使用本产品的数据或进入未经许可的服务器及帐户；</span
          ><span style="font-family: Raleway">2</span
          ><span style="font-family: 宋体"
            >）未经允许对本产品的数据库及软件系统进行删除、修改、增加存储信息的行为；</span
          ><span style="font-family: Raleway">3</span
          ><span style="font-family: 宋体"
            >）未经许可，企图扫描探测本产品及服务器的漏洞、弱点或其它实施破坏网络安全的行为；</span
          ><span style="font-family: Raleway">4</span
          ><span style="font-family: 宋体"
            >）任何企图干涉、破坏本产品系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</span
          ><span style="font-family: Raleway">5</span
          ><span style="font-family: 宋体">）任何其他危害计算机信息网络安全的行为。</span></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">3.3</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">根据《中华人民共和国教师法》的相关规定：教师负有</span
          ><span style="font-family: Raleway">“</span><span style="font-family: 宋体">教书育人</span
          ><span style="font-family: Raleway">”</span
          ><span style="font-family: 宋体"
            >之职责，应以学生的德、智、体、美、劳全面发展为己任，为了呵护学生的权益您应保证：</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap">
        <span style="font-family: Raleway; color: rgb(102, 102, 102); font-size: 14px; background: rgb(255, 255, 255)"
          >1<span style="font-family: 宋体"
            >）对于通过本产品获悉的任何院校的学生个人隐私、信息、数据及资料（包括但不限于学生的学业成绩、课业情况等信息）都负有保密义务，除了学校主体部门的正常业务数据请求外，
            未征的学生本人同意或相关部门批准，不得对外扩散上述学生信息。</span
          ><span style="font-family: Raleway">2</span
          ><span style="font-family: 宋体"
            >）使用该平台应遵循文明礼貌原则，不得进行辱骂、讽刺、挖苦、歧视学生的行为。</span
          ></span
        >
      </p>
      <p style="font-family: 等线; font-size: 14px; text-wrap: wrap; line-height: 28px; background: rgb(255, 255, 255)">
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第四条</span>
            <span style="font-family: 宋体">知识产权及相关责任</span></span
          ></strong
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 16px">4.1</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 16px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 16px"
          ><span style="font-family: 宋体">用户在使用本</span></span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >产品时所发表或上传的文字、图片、视频、音频、网页连接以及其他形式的附件、文档、文字等均应采用本人原创信息。如引用或使用他人资料应征得原创者同意。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">4.2</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">用户应保证在使用本产品时发表或上传的</span>5.1<span style="font-family: 宋体"
            >条款规定信息，均不侵犯任何第三方知识产权，如第三方提出权利主张的，用户应自行承担责任。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">4.3</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >如果我们收到权利人通知，主张您发送或传播的内容侵犯其相关权利的，您同意我们有权进行独立判断并采取删除、屏蔽或断开链接等措施，并不承担任何责任。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">4.4</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >我们收集、保存、传输、使用您的内容将不违反相关法律规定，并适用我们的用户《隐私政策》，受其约束。</span
          ></span
        >
      </p>
      <h3
        style="
          margin-top: 0;
          margin-bottom: 0;
          font-family: 宋体;
          font-size: 18px;
          text-wrap: wrap;
          line-height: 36px;
          background: rgb(255, 255, 255);
        "
      >
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第五条</span> <span style="font-family: 宋体">其他免责声明</span></span
          ></strong
        >
      </h3>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">5.1</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户因违反本协议或相关的服务协议规定侵犯第三方任何合法权益，导致或产生的任何第三方主张的索赔、要求或损失，本公司和平台运营管理方不承担任何责任。对此，本公司和平台运营管理方有权视用户的行为性质，</span
          >
          <span style="font-family: 宋体"
            >采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收账号、追究法律责任等措施。对恶意注册账号或利用账号进行违法活动、捣乱、骚扰、欺骗以及其他违反本协议的行为，</span
          >
          <span style="font-family: 宋体">有权回收其账号，并保留法律追责的权利。</span></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">5.2</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >平台运营管理方或用户提供的全部信息仅依照此信息当前现状提供仅供用户参考，本公司不对前述信息的准确性、完整性和适用性做任何的承诺和保证，用户应对平台运营管理方或用户提供的信息自行判断，</span
          >
          <span style="font-family: 宋体">并承担因使用前述信息引发的全部风险，</span>
          <span style="font-family: 宋体">本公司无需对因用户使用前述信息的任何行为导致的任何损失承担责任。</span></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">5.3</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >对于因不可抗力或本公司不能预料、不能控制的原因（包括但不限于计算机病毒或黑客攻击、系统不稳定、用户使用不当、平台运营管理方人员的误操作、以及任何其他技术、互联网络、通信线路原因等）</span
          >
          <span style="font-family: 宋体"
            >产生的包括但不限于计算机用户信息和数据安全问题，用户个人信息的安全问题等给用户或任何第三方造成的损失，本公司不承担任何责任。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">5.4</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >用户在使用本产品及服务的过程中，可能存在来自任何其他用户的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为，也可能存在对他人权利（包括知识产权）造成侵犯的匿名、冒名、或伪造的各种信息或行为，</span
          >
          <span style="font-family: 宋体"
            >用户须自行判断相关内容、信息、行为的安全性等风险，并自行独立承担因上述行为给平台运营管理方、其他用户或我公司造成损害的一切法律后果。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">5.5</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >本公司对平台运营管理方通过本产品提供的全部服务及内容不作任何形式的承诺或保证，不论是明确的或暗示的。前述承诺或保证包括但不限于：第三方通过本产品提供的服务或内容的真实性、时效性、对特定用途的适用性、</span
          >
          <span style="font-family: 宋体"
            >任何形式的所有权保证，非侵权保证等。对因前述第三方服务内容导致的任何直接、间接、偶然、特殊及后续的损害，本公司不承担任何责任。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">5.6</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >本公司和平台运营管理方对在为用户提供信息存储空间服务的同时，十分重视互联网环境的绿色、健康以及其他用户的知识产权和其他合法权益的保护。用户在上传分享内容时，须注意不得侵犯任何个人、</span
          >
          <span style="font-family: 宋体">企事业单位或社会团体的合法权益</span>,<span style="font-family: 宋体"
            >包括但不限于姓名权、名称权、名誉权、荣誉权、肖像权、隐私权、知识产权等权利，否则，用户应对其自身行为及对由此产生的任何后果承担全部法律责任。
            本产品为用户提供上传服务供用户分享内容，对其上传的内容不做任何修改或编辑，但我们决反对任何违反中华人民共和国法律法规的行为。</span
          ></span
        >
      </p>
      <h3
        style="
          margin-top: 0;
          margin-bottom: 0;
          font-family: 宋体;
          font-size: 18px;
          text-wrap: wrap;
          line-height: 36px;
          background: rgb(255, 255, 255);
        "
      >
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第六条</span>
            <span style="font-family: 宋体">服务的变更／中断和终止</span></span
          ></strong
        >
      </h3>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >您理解并同意，本公司提供的产品和服务是按照现有技术和条件所能达到的现状提供的。本公司会尽最大努力向您提供服务，确保服务的连贯性和安全性；但本公司不能随时预见和防范法律、技术以及其他风险，</span
          >
          <span style="font-family: 宋体"
            >包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、配合政府工作等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</span
          ></span
        >
      </p>
      <h3
        style="
          margin-top: 0;
          margin-bottom: 0;
          font-family: 宋体;
          font-size: 18px;
          text-wrap: wrap;
          line-height: 36px;
          background: rgb(255, 255, 255);
        "
      >
        <strong
          ><span style="font-family: Raleway; line-height: 32px; color: rgb(51, 51, 51); font-size: 16px"
            ><span style="font-family: 宋体">第七条</span> <span style="font-family: 宋体">其他条款</span></span
          ></strong
        >
      </h3>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">7.1</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >本协议的订立、执行和解释及争议的解决均应适用中国法律。倘本协议之任何规定因与中华人民共和国法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。</span
          >
          <span style="font-family: 宋体"
            >本协议的签署地点为本公司所在地区，若用户与本公司发生争议，双方同意将争议提交本公司所在地区法院诉讼解决。</span
          ></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">7.2</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体"
            >本协议可能因国家政策、产品以及履行环境发生变化而进行修改，本公司会将修改后的协议发布到本产品的网站及客户端应用中。若您对修改后的协议有异议的，请立即停止登录和使用本产品及服务，</span
          >
          <span style="font-family: 宋体">若您登录或继续使用该产品及服务，视为认可修改后的协议。</span></span
        >
      </p>
      <p
        style="
          margin-top: 20px;
          margin-bottom: 5px;
          font-family: 宋体;
          font-size: 16px;
          text-wrap: wrap;
          background: rgb(255, 255, 255);
        "
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">7.3</span
        ><span style="font-family: Cambria; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span
        ><span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px"
          ><span style="font-family: 宋体">本协议及本协议的任何条款内容的最终解释权及修改权归本公司所有。</span></span
        >
      </p>
      <p
        style="margin: 20px 0 5px; font-family: 等线; font-size: 14px; text-wrap: wrap; background: rgb(255, 255, 255)"
      >
        <span style="font-family: Raleway; color: rgb(51, 51, 51); font-size: 14px">&nbsp;</span>
      </p>
      <p style="text-align: justify; font-family: 等线; font-size: 14px; text-wrap: wrap">
        <span style="font-size: 14px">&nbsp;</span>
      </p>
      <p>
        <br />
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
.index {
  display: contents;
  .banner {
    background-image: url('../../../assets/newIndex/hope1.png');
    height: 376px;
    max-width: 1920px;
    margin: 0 auto;
    background-size: cover;
    .txt {
      width: 653px;
      height: 210px;
      font-size: 50px;
      font-family: Alibaba PuHuiTi -52 Regular, Alibaba PuHuiTi 30;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
      padding-top: 132px;
    }
  }
  .content {
    margin: 84px auto 100px auto;
    justify-content: space-between;
    width: 1200px;
  }
}
ul li {
  list-style: none;
}
</style>